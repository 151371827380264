<template>
  <div class="ranking-list">
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <template v-else-if="items.length">
      <ranking-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
      ></ranking-item>
    </template>
    <div class="text-center" v-else>
      No hay datos en este ranking
    </div>
  </div>
</template>

<script>
import RankingItem from '@/components/ranking/RankingItem'
import axios from 'axios'

export default {
  name: 'Areas',
  components: {
    RankingItem
  },
  props: {
    type: String
  },
  data () {
    return {
      items: [],
      loading: false
    }
  },
  watch: {
    type () {
      this.fetchRanking()
    }
  },
  methods: {
    async fetchRanking () {
      this.loading = true
      const rankingResponse = await axios.get(`api/flashcards/ranking/por_tipo/?tipo=${this.type}`)
      this.items = rankingResponse.data
      this.loading = false
    }
  },
  async mounted () {
    this.fetchRanking()
  }
}
</script>

<style>
</style>

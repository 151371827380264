var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',{staticClass:"ranking-item",class:{
    'ranking-item--secondary': _vm.item.puesto % 2 !== 0,
    'ranking-item--current-user': _vm.isCurrentUser(_vm.item.user.id),
  }},[_c('p',{staticClass:"ranking-item__number",class:{
      'ranking-item__number--first-places': _vm.item.puesto < 4,
      'ranking-item__number--small': _vm.item.puesto.toString().length > 2
    }},[_vm._v(" "+_vm._s(_vm.item.puesto)+" ")]),_c('img',{staticClass:"ranking-item__image",attrs:{"src":_vm.getDisplayPhoto(_vm.item.user),"height":"37px","width":"37px","alt":""}}),_c('p',{staticClass:"ranking-item__text"},[_vm._v(" "+_vm._s(_vm.getDisplayname(_vm.item.user))+" ")]),_c('v-spacer'),_c('p',{staticClass:"ranking-item__text ranking-item__text--bold"},[_vm._v(" "+_vm._s(Math.floor(_vm.item.puntaje))+" pts ")]),_c('div',{staticClass:"ranking-item__medall-container"},[(_vm.item.puesto < 4)?_c('v-img',{staticClass:"ranking-item__medall",attrs:{"src":_vm.getMedal(_vm.item.puesto)}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="ranking-item"
    v-if="item"
    :class="{
      'ranking-item--secondary': item.puesto % 2 !== 0,
      'ranking-item--current-user': isCurrentUser(item.user.id),
    }"
  >
    <p
      class="ranking-item__number"
      :class="{
        'ranking-item__number--first-places': item.puesto < 4,
        'ranking-item__number--small': item.puesto.toString().length > 2
      }"
    >
      {{ item.puesto }}
    </p>
    <img
      class="ranking-item__image"
      :src="getDisplayPhoto(item.user)"
      height="37px"
      width="37px"
      alt=""
    >
    <p class="ranking-item__text">
      {{ getDisplayname(item.user) }}
    </p>
    <v-spacer></v-spacer>
    <p class="ranking-item__text ranking-item__text--bold">
      {{ Math.floor(item.puntaje) }} pts
    </p>
    <div class="ranking-item__medall-container">
      <v-img
        v-if="item.puesto < 4" :src="getMedal(item.puesto)"
        class="ranking-item__medall"
      ></v-img>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import defaultUserPhoto from '@/assets/img/ranking/foto-prueba.svg'
import goldMedalImage from '@/assets/img/ranking/gold-medal.png'
import silverMedalImage from '@/assets/img/ranking/silver-medal.png'
import bronzeMedalImage from '@/assets/img/ranking/bronze-medal.png'

export default {
  name: 'RankingItem',
  props: {
    item: Object
  },
  computed: {
    ...mapState('profile', ['user'])
  },
  methods: {
    getDisplayname (user) {
      if (user.first_name && user.last_name) {
        const displayName = `${user.first_name.split(' ')[0]} ${user.last_name.split(' ')[0]}`
        return displayName.substring(0, 19)
      }
      return user.id.toString()
    },
    getDisplayPhoto (user) {
      const foto = defaultUserPhoto
      if (user.foto !== '' && user.foto) {
        return user.foto
      } else {
        return foto
      }
    },
    getMedal (index) {
      if (index === 1) {
        return goldMedalImage
      }
      if (index === 2) {
        return silverMedalImage
      }
      if (index === 3) {
        return bronzeMedalImage
      }
      return ''
    },
    isCurrentUser (uid) {
      return this.user?.id === uid
    }
  }
}

</script>

<style scoped lang="scss">
.ranking-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 9px 20px;
  height: 54px;

  &--current-user {
    background-color: #12A2D6 !important;
    color: #FDFDFD;
    border-radius: 5px;

    .ranking-item__number {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 50%;
      letter-spacing: -1;
      font-weight: 600;
      color: #12A2D6;
      height: 40px;
      width: 40px !important;
      max-width: 40px !important;
      min-width: 40px !important;
      margin: 0 15px;
    }

    .ranking-item__text {
      color: #FDFDFD;
    }

    .ranking-item__text--bold {
      font-weight: 700;
    }
  }

  &--secondary {
    background: #F5F5F5;
  }

  &__number {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #C4C4C4;
    margin-bottom: 0;
    padding-right: 15px;
    padding-left: 15px;
    width: 70px;
    max-width: 70px;
    min-width: 70px;

    &--first-places {
      color: #F3B500;
    }

    &--small {
      font-size: 16px;
    }
  }

  &__image {
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
    margin: 0 12px;
    border-radius: 50%;
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3F3D56;
    margin-bottom: 0;
  }

  &__medall-container {
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__medall {
    max-width: 25px;
    margin: 0 25px;
  }
}

@media screen and (max-width: 736px) {
  .ranking-item {
    padding: 4px 8px;

    &--current-user {
      .ranking-item__number {
        margin: 0 2.5px;
      }
    }

    &--small {
      font-size: 16px;
    }

    &__number {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      padding-right: 0;
      padding-left: 0;
      width: 45px;
      max-width: 45px;
      min-width: 45px;
    }

    &__image {
      margin: 0 10px;
    }

    &__text {
      font-size: 14px;
      line-height: 25px;
    }

    &__medall-container {
      min-width: 45px;
    }

    &__medall {
      max-width: 30px;
      margin: 0 10px;
    }
  }
}

</style>

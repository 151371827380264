<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    overlay-color="black"
    overlay-opacity="0.75"
    class="dialog-modal-premium"
    style="border: 2px solid white;"
    :width="tamanioModal"
    max-width="620px"
  >
    <v-card class="dialog-modal-premium__card" width="100%">
      <v-col class="d-flex flex-column pt-0" cols="12">
        <v-row class="dialog-modal-premium__title-container py-8 px-8 px-sm-6" justify="center">
          <div class="dialog-modal-premium__title-style-container d-flex justify-space-between">
            <p class="dialog-modal-premium__title-style mb-0">Ranking</p>
          </div>
        </v-row>
        <v-row class="d-flex flex-row py-5 py-sm-10 align-self-center">
          <div class="d-flex flex-column align-center">
            <div class="dialog-modal-premium__benefits d-flex pb-2">
              <v-img
                src="@/assets/img/ranking/check-icon.svg" class="dialog-modal-premium__check mr-5 mr-sm-10"
                height="12.96"
                max-width="17.91"
                contain
              />
              <p class="dialog-modal-premium__message-style">¡Bienvenido al ranking! Aquí encontrarás una lista de estudiantes clasificados según su desempeño.</p>
              <div class="d-none d-sm-block" style="width: 85px; opacity: 0;"></div>
            </div>
            <div class="dialog-modal-premium__benefits d-flex pb-2">
              <v-img
                src="@/assets/img/ranking/check-icon.svg" class="dialog-modal-premium__check mr-5 mr-sm-10"
                height="12.96"
                max-width="17.91"
                contain
              />
              <p class="dialog-modal-premium__message-style">Puedes cambiar entre diferentes tipos de ranking: mensual, anual y general. Usa los botones de selección en la parte superior para alternar entre ellos.</p>
              <div class="d-none d-sm-block" style="width: 85px; opacity: 0;"></div>
            </div>
            <div class="dialog-modal-premium__benefits d-flex pb-2">
              <v-img
                src="@/assets/img/ranking/check-icon.svg" class="dialog-modal-premium__check mr-5 mr-sm-10"
                height="12.96"
                max-width="17.91"
                contain
              />
              <p class="dialog-modal-premium__message-style">
                Para sumar puntos, simplemente resuelve flashcards, lo cual te otorgará 1 punto por cada flashcard aprendida. Por otro lado, aprender una flashcard personalizada solo suma 0.1 puntos.
              </p>
              <div class="d-none d-sm-block" style="width: 85px; opacity: 0;"></div>
            </div>
            <div class="dialog-modal-premium__benefits d-flex pb-2">
              <v-img
                src="@/assets/img/ranking/check-icon.svg" class="dialog-modal-premium__check mr-5 mr-sm-10"
                height="12.96"
                max-width="17.91"
                contain
              />
              <p class="dialog-modal-premium__message-style mb-0">
                Recuerda que cada vez que haces una flashcard en modo <b>repaso</b> no suma puntos. ¡Haz tu mejor esfuerzo y sube en el ranking!
              </p>
            </div>
          </div>
          <v-img
            src="@/assets/img/ranking/ranking-modal-character.svg"
            max-width="104"
            max-height="299"
            class="dialog-modal-premium__image d-none d-sm-flex"
            style="position: absolute;right: 20px;top: 15px;"
          />
        </v-row>
        <v-row class="d-flex justify-center mb-5">
          <div class="dialog-modal-premium__button d-flex justify-center">
            <usamedic-button width="75%" @click="convertToPremium">Aceptar</usamedic-button>
          </div>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import UsamedicButton from '@/components/buttons/UsamedicButton'
import { mapActions, mapState } from 'vuex'

export default {
  components: { UsamedicButton },
  props: {},
  data () {
    return {
      messages: []
    }
  },
  computed: {
    ...mapState('perfil', ['user']),
    tamanioModal () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '90%'
        case 'sm':
          return '80%'
        case 'md':
          return '60%'
        case 'lg':
          return '50%'
        case 'xl':
          return '35%'
        default:
          return '50%'
      }
    }
  },
  methods: {
    ...mapActions('perfil', ['fetchUser']),
    async convertToPremium () {
      if (this.user.is_premium) {
        this.$root.$confirm({
          title: 'Buenas noticias!',
          message: 'Tu ya cuentas con una membresia premium, estamos trabajando para darte nuevas funcionalidades'
        })
      } else {
        this.$root.$confirm({
          title: 'UPS!',
          message: 'Pronto podrás ser premium. Si eres alumno de algún curso, comunicate con Usamedic'
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.dialog-modal-premium {
  border-radius: 20px !important;

  &__card {
    border-radius: 20px !important;
  }

  &__benefits {
    width: 85%;
  }

  &__title-container {
    background: linear-gradient(90deg, rgba(31, 193, 208, 1), rgba(5, 131, 218, 1));
    border-radius: 20px;
    overflow: hidden;
  }

  &__title-style-container {
    width: 100%;
  }

  &__title-style {
    font-size: 20px;
    font-weight: 600;
    color: white;
    width: 100%;
  }

  &__message-style {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
  }

  &__check {
    width: 17.91px !important;
  }

  &__button {
    width: 100%;
  }

  &__image {
  }
}

@media (min-width: 960px) {
  .dialog-modal-premium__title-style-container {
    width: 70%;
  }
}

@media (min-width: 700px) {
  .dialog-modal-premium__title-style-container {
    width: 70%;
  }
}

@media (max-width: 700px) {
  .dialog-modal-premium__image {
    position: absolute;
    margin-top: -30%;
    margin-left: 350px;
    overflow: hidden;
  }
  .dialog-modal-premium__title-style-container {
    width: 90%;
    margin-top: 0%;
  }
}

@media (max-width: 460px) {
  .dialog-modal-premium__image-header {
    margin-top: 16%;
    position: relative;
  }
  .dialog-modal-premium__message-style {
    font-size: 11px;
    margin-bottom: 6px;
  }
}

@media (max-width: 400px) {
  .dialog-modal-premium__image-header {
    margin-top: 0;
    position: relative;
  }
  .dialog-modal-premium__title-style-container {
    width: 100%;
  }
  .dialog-modal-premium__message-style {
    font-size: 11px;
    margin-bottom: 6px;
  }
}

@media (max-width: 281px) {
  .dialog-modal-premium__image-header {
    visibility: hidden;
    display: none;
  }
}

::v-deep .v-dialog--active {
  border-radius: 20px !important;
}
</style>

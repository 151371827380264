<template>
  <UnitsFrame class="ranking-frame">
    <div class="d-flex align-center">
      <p class="section__text--title mt-5 mb-4 mr-3">Ranking</p>
      <v-btn icon @click="showInstructions = true" color="#262626">
        <v-icon>mdi-alert-circle-outline</v-icon>
      </v-btn>
    </div>
    <v-card class="ranking-card">
      <div class="ranking-container">
        <v-select
          v-model="currentRankingOption"
          :items="rankingOptions"
          item-text="text"
          item-value="value"
          solo
          class="ranking-option-field field ml-auto"
          color="primary"
          ref="gender"
        />
        <div>
          <ranking-list :type="currentRankingOption"/>
        </div>
      </div>
    </v-card>
    <ranking-instructions-modal v-model="showInstructions"></ranking-instructions-modal>
  </UnitsFrame>
</template>

<script>
import UnitsFrame from '@/components/units/UnitsFrame'
import RankingList from '@/components/ranking/RankingList'
import RankingInstructionsModal from '@/components/ranking/RankingInstructionsModal'

export default {
  components: {
    UnitsFrame,
    RankingList,
    RankingInstructionsModal
  },
  data () {
    return {
      rankingOptions: [
        { text: 'Mensual', value: 'MENSUAL' },
        { text: 'Anual', value: 'ANUAL' },
        { text: 'General', value: 'GENERAL' }
      ],
      currentRankingOption: 'MENSUAL',
      showInstructions: false
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  border-radius: 15px !important;
  width: 190px;
  &__region {
    margin-left: 0;
  }
}
.ranking-card {
  width: 100%;
  margin: auto;
  padding: 40px 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px;
}
.ranking-container {
  max-width: 900px;
  min-height: 500px;
  margin: auto;
}
.ranking-option-field {
  width: 210px;
}
.ranking-option-field ::v-deep .v-input__slot {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  padding: 0 20px!important;
}
.ranking-option-field ::v-deep .v-select__selection--comma {
  color: #12A2D6 !important;
}
</style>
